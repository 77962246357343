import React, { Component } from 'react';
import { connect } from 'react-redux';

class Footer extends Component {

  render() {
    /* html bin
    <span className="float-right small"><p>&copy; {new Date().getFullYear()} Enter IT Ltd Quasar</p></span>
          <span className="col-8"><p style={{color: brandings.branding && brandings.branding.TextColour}}> Enter IT Limited, 7-10 Chandos Street, Cavendish Square, London, W1G 9DQ | Registered in England 3887613.</p></span>
    */
    const {brandings} = this.props;
    return (

    <footer className="footer" style={{background: brandings.branding && brandings.branding.BackgroundColour}}>
      <div className="container">
        <div className="row">
          <span className="col-4"><a href="https://mi-crow.com/"><p style={{color: brandings.branding && brandings.branding.TextColour}}>© Mi Crow Courses</p></a></span>

        </div>
      </div>
    </footer>

    )
  }
}

function mapStateToProps(state) {
    const { brandings } = state;
    return {
      brandings
    };
}

const connectedFooter = connect(mapStateToProps)(Footer);

export default connectedFooter;
