import hexToRgb from './hexToRgb';

export function applyBranding(branding){
  var bgRGB = hexToRgb(branding.BackgroundColour)
  if(branding.Opacity === null || branding.Opacity === undefined){
    branding.Opacity = 1;
  }

  //Body background and translucent background colour
  document.body.style.background = "linear-gradient(rgba(" + bgRGB.r + "," + bgRGB.g + "," + bgRGB.b + ", " + (0.95 * branding.Opacity) + "),rgba(" + bgRGB.r + "," + bgRGB.g + "," + bgRGB.b + ", " + (0.95 * branding.Opacity) + ")), url(\"/img/Branding/" + branding.Wallpaper + "\")";

  //Set default text colour
  console.log(branding.PageTitle, branding.FaviconImage);
  document.body.style.color = branding.TextColour;
  if(branding.PageTitle){
  document.title = branding.PageTitle;
  }
  else{
    document.title = "Mi Crow";
  }
  if(branding.FaviconImage){
  document.getElementById('faviconImage').href = '/img/Branding/' + branding.FaviconImage;
  }
  else{
    document.getElementById('faviconImage').href = '/img/Branding/microw_favicon.png';
  }
}
