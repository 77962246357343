import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Catalogue from "../components/Catalogue";
import Jumbotron from '../components/Jumbotron';
import { settings } from '../helpers/settings';


class OutOfCredits extends Component {
  render() {
  return (
    <div className="OutOfCredits">
      <Navbar />
      <Jumbotron title={"Well, this is " + (settings.Crowisms.Enabled ? "HAWKward": "Awkward") } subtitle={"Looks like you have run out of credits"} />
      <div className="container">
        <h2>
          What do I do now…
        </h2>
        <p>
        Please get in touch with your systems administrator or the Mi Crow Team to get more credits.  Don’t worry it won’t be long before you’re back on the runway, enjoying your next {"Mi Crow".replace(/ /g, "\u00a0")} flight.
        </p>
      </div>

    </div>
  )
  }
}

export default OutOfCredits
